.css-selector {
  background: linear-gradient(250deg, #00ace5, #8da698, #007399, #f2bf27);
  background-size: 800% 800%;

  -webkit-animation: AnimationName 25s ease infinite;
  -moz-animation: AnimationName 25s ease infinite;
  -o-animation: AnimationName 25s ease infinite;
  animation: AnimationName 25s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 35% 0%;
  }
  50% {
    background-position: 66% 100%;
  }
  100% {
    background-position: 35% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 35% 0%;
  }
  50% {
    background-position: 66% 100%;
  }
  100% {
    background-position: 35% 0%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 35% 0%;
  }
  50% {
    background-position: 66% 100%;
  }
  100% {
    background-position: 35% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 35% 0%;
  }
  50% {
    background-position: 66% 100%;
  }
  100% {
    background-position: 35% 0%;
  }
}
